<template>
  <main class="mt-5 admin-recommendation">
    <div class="card mt-3">
      <div class="card-header  border-0 d-flex w-100">
        <h5 class="card-title font-bold">گروه پیشنهادی: <span class="font-weight-bold">{{ getGroup(group) }}</span></h5>
        <div style="flex:1"></div>
        <button class="btn btn-success" v-b-modal.recommendation-modal>افزودن محصول</button>
      </div>
      <div class="card-body row">
        <table :style="disabled ? 'opacity: 0.8' : ''"
               class="table table-responsive-sm table-bordered table-hover">
          <thead>
          <tr>
            <th scope="col"><i class="fas fa-arrows-alt-v"></i>شناسه</th>
            <th scope="col">محصول</th>
            <th scope="col">حذف</th>
          </tr>
          </thead>
          <draggable v-model="items" tag="tbody" @change="sortItems" v-bind="dragOptions">
            <tr class='flip-list' v-for="(item) in items" :key="item.id">
              <td scope="row">{{ item.id }}</td>
              <td scope="row">{{ item.product.id + ' - ' + item.product.title }}</td>
              <td class="text-center">
                <button :id="'delete-btn-' + item.id" @click="deleteItem(item.id)"
                        class="btn btn-danger"><i class="fa fa-close"></i></button>
              </td>
            </tr>
          </draggable>
        </table>
        <div class="alert alert-danger text-right" v-if="items == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </div>
    </div>
    <b-modal @shown="loadProducts" id="recommendation-modal" title="افزودن محصول" :ok-disabled="disabled"
             :cancel-disabled="disabled" @ok.prevent="newItem" class="in-form-modal" ok-title="افزودن" cancel-title="لغو" size="lg">
      <form v-if="products" :action="url">
        <b-form-group label="انتخاب محصول">
          <multiselect
              v-model="selectedProduct"
              placeholder="انتخاب کنید"
              :allow-empty="false"
              open-direction="bottom"
              :options="products"
              label="label"
              track-by="id"
              :searchable="true"
              :multiple="false"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
          >
            <template slot="noOptions">
              موردی یافت نشد
            </template>
            <template slot="noResult">
              موردی یافت نشد
            </template>
          </multiselect>
        </b-form-group>
      </form>
      <div v-else>در حال بارگزاری</div>

    </b-modal>
  </main>
</template>

<script>

export default {
  name: "Recommendation",
  props: {
    group: String
  },
  components: {
  },
  data() {
    return {
      url: 'api/admin/recommendations/groups/' + this.group,
      items: null,
      disabled: false,
      selectedProduct: null,
      products: null
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.disabled,
        ghostClass: "ghost"
      }
    }
  },
  created() {
    this.loadItems()
  },
  methods: {
    getGroup(name) {
      if (name == 'with_discount') {
        return 'منتخب تخفیف دار';
      } else {
        return 'پیشنهادی آلبا مارکت '
      }
    },
    deleteItem(id) {
      window.swal({
        title: "آیتم حذف شود؟",
        text: "این عملیات غیر قابل بازگشت خواهد بود",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        dangerMode: true,
        buttons: ["خیر", "بله حذف کن"]
      })
          .then((willDelete) => {
            if (willDelete) {
              this.$axios.delete(`api/admin/recommendations/${id}`)
                  .then((response) => {
                    this.items = this.items.filter(item => item.id != id)
                    window.swal({
                      icon: "success",
                      showConfirmButton: true,
                      text: response.data.message,
                      button: "باشه",
                    })
                  })
                  .catch(error => {
                    window.swal({
                      title: 'خطا',
                      showConfirmButton: true,
                      text: error.response.data.message,
                      button: "باشه",
                    })
                  })
            }
          })
          .catch(() => {

          })
    },
    async newItem() {
      try {
        const response = await this.$axios.post('api/admin/recommendations', {
          group: this.group,
          product_id: this.selectedProduct.id
        });
        this.$root.success_notification(response.data.message);
        this.items.push(response.data.data.recommendation);
        // window.location.reload()
      } catch (error) {
        this.$root.error_notification(error);
      }
    },
    async loadItems () {
      const response = await this.$axios.get(this.url)

      this.items = response.data.data.recommendations
    },
    async sortItems() {
      let orders = [];

      this.items.forEach((item) => {
        orders.push(item.id)
      });
      try {
        this.disabled = true;
        const response = await this.$axios.post(`api/admin/recommendations/groups/${this.group}/sort`, {
          group: this.group,
          ids: orders,
        })
        this.$root.success_notification(response.data.message);
      } catch (error) {
        this.$root.error_notification(error)
      } finally {
        this.disabled = false
      }
    },
    async loadProducts() {
      if (this.products) {
        return;
      }
      const response = await this.$axios.get('api/admin/products/all')
      this.products = response.data.data.map(p => {
        p.label = p.id + ' - ' + p.title
        return p
      })
    }

  }
}
</script>

<style scoped>
  .admin-slider .table > thead > tr > td,
  .admin-slider .table > thead > tr > th {
    font-weight: normal;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
